import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServicePagePortal from '../../components/service-page-portal';

class ServiceInformation extends Component {
    render() {
        return (
            <Layout>
                <div className="service-information-page">
                    <HelmetEx>
                        <div metadata="title">Free Estimates |Services | Elmwood Park NJ</div>
                        <div metadata="keywords">services,Berge,County,NJ</div>
                        <div metadata="description">
                            WE help you set your decorating budget. Give us a call for a FREE Estimate
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>SERVICE INFORMATION</h1>
                                            <h3>We help you set your decorating budget</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <ServicePagePortal activePath={"/services/information-service"}>
                                <section>
                                    <h4>FREE ESTIMATES</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">WE help you set your decorating budget. Give us a call for a FREE Estimate</p>
                                </section>
                            </ServicePagePortal>
                        </div>
                    </section>
                </div>
            </Layout >
        )
    }
}

export default ServiceInformation;